import _ from 'underscore';
import axios from 'axios';

export default function(url, params = {}, type = 'get') {
    type = type.toLowerCase();
    switch(type) {
        case 'post':
            let formData = null;
            if(!params instanceof FormData) {
                formData = new FormData();
                _.each(params, (value, key) => {
                    formData.append(key, value);
                });
            }
            else {
                formData = params;
            }

            return new Promise((resolve, reject) => {
                const request = axios({
                    method: 'post',
                    url:  url,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        reject('Statuscode != 200');
                    }
                    resolve(response.data);
                })
                .catch((err) => {
                    window.globalLoader.loadingFinished(url);
                    reject(err);
                });
            });
            break;

        case 'delete':
            return new Promise((resolve, reject) => {
                const request = axios.delete( url)
                    .then((response) => {
                        if (response.status !== 200) {
                            reject('Statuscode != 200');
                        }
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
            break;

        default: // get
            return new Promise((resolve, reject) => {
                const request = axios.get( url, { params: params })
                    .then((response) => {
                        if (response.status !== 200) {
                            reject('Statuscode != 200');
                        }
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
    }
}
