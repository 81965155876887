import j77SystemTranslationVue from '../../Plugins/j77SystemTranslationVue'
import DnsManagerClientApp from './DnsManagerClientApp';
import { createApp } from 'vue';

window.__VUE_OPTIONS_API__ = true;
window.__VUE_PROD_DEVTOOLS__ = false;

function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
    if (document.getElementById('dns-manager-client-app')) {

        let app = createApp(DnsManagerClientApp)
            .use(j77SystemTranslationVue);

        app.mount('#dns-manager-client-app');         
    }
});


