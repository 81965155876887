<template>
	<div class="dns-manager-client-app-loaded">
		<DnsManagerClient></DnsManagerClient>
	</div>
</template>

<script>
import DnsManagerClient from '../../Components/BaseViews/DnsManagerClient.vue';

export default {
	name: 'DnsManagerClientApp',
	data: function () {
		return {
		};
	},
	created() {
	},
	methods: {
	},
	components: {
    DnsManagerClient
}
}
</script>

<style>

</style>
