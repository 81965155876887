<template>
	<div :class="['certificates-view']">
		<h1>{{ $gettext('Manage Certificates for DNS Zone') }} <b>{{ dnsZone.name }}</b></h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="$emit('goBack')">{{ $gettext('Back to list') }}</a>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showCreateCertificateModal">{{ $gettext('Create new Certificate') }}</a>
		<RessourceTable :class="{'certificateTable': true}" :dataRows="certificateList" :allProps="certificateTableAllProps" @click="certificateTableClickHandler" />
	</div>
	<BootstrapModal id="createCertificate" modalSize="large" :title=" $gettext('Create a new Certificate') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="createCertificate()"  :submitDisabled="createCertificateSubmitDisabled">
		<CertificateForm v-if="modalTempData && modalTempData.newCertificate" :dnsZone="dnsZone" :certificate="modalTempData.newCertificate" />
	</BootstrapModal>
	<BootstrapModal id="showCertificate" modalSize="large" :title=" $gettext('Show Certificate') " :uiCloseVisible="true" :uiSubmitVisible="false">
		<template v-if="this.modalTempData && Object.keys(this.modalTempData).includes('showCertificate')">
			<strong>{{ $gettext('Certificate (Full chain)') }}</strong><br/>
			<p class="text-small text-muted">{{ $gettext('You can store the WHOLE text into one .pem-file and e.g. use it with any webserver as an SSL certificate. As you can see, the text contains multiple "certificates". This is intended, as we need a whole certificate chain.') }}</p>
			<button class="btn btn-primary" @click="download('cert')">Download</button>
			<button class="btn btn-secondary" @click="modalTempData.showCertContent = !modalTempData.showCertContent">{{ this.modalTempData.showCertContent ? $gettext('Hide content') : $gettext('Show content') }}</button><br/><br/>
			<pre v-if="modalTempData && modalTempData.showCertContent && modalTempData.showCertificate && modalTempData.showCertificate.certificateContent" v-html="modalTempData.showCertificate.certificateContent"></pre>
			
			<strong>{{ $gettext('Private key') }}</strong><br/>
			<p class="text-small text-muted">{{ $gettext('You can store this text into a .key-file and e.g. use it with any webserver. Please make sure to store it in a safe place (file permissions?), because this is the private key and should never ever be readable for any unauthorized person!') }}</p>
			<button class="btn btn-primary" @click="download('privateKey')">Download</button>
			<button class="btn btn-secondary" @click="modalTempData.showPrivate = !modalTempData.showPrivate">{{ this.modalTempData.showPrivate ? $gettext('Hide content') : $gettext('Show content') }}</button><br/><br/>
			<pre v-if="modalTempData && modalTempData.showPrivate && modalTempData.showCertificate && modalTempData.showCertificate.privateKey" v-html="modalTempData.showCertificate.privateKey"></pre>

			<strong>{{ $gettext('Scripted download') }}</strong><br/>
			<p class="text-small text-muted">{{ $gettext('You can use the API or our downloadCert.sh bash script to download this certificate. The downloadCert.sh script is documented in our invokable.wiki system.<br/>You will need a dnsZoneId and a certificateId for download.') }}</p>
			<pre>{{ $gettext('Id of DNS zone') + ': ' + dnsZone.id + "\n" + $gettext('Id of Certificate') + ': ' + modalTempData.showCertificate.id }}</pre>
		</template>
	</BootstrapModal>
	<BootstrapModal id="deletionConfirmCertificate" :title="$gettext('Delete?')" :uiCloseVisible="true" :submitTitle="$gettext('Delete')" @submit="deleteCertificate()">
		{{ $gettext('Are you sure you want to delete this Certificate?') }}
	</BootstrapModal>
	<BootstrapModal id="renewConfirm" :title="$gettext('Renew?')" :uiCloseVisible="true" :submitTitle="$gettext('Mark for renew')" @submit="renewCertificate()">
		{{ $gettext('Are you sure you want to mark this certificate to be renewed now? The renew itself will be done in asynchroneously in the background withing a few minutes.') }}
	</BootstrapModal>
</template>

<script>

import _ from 'underscore';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';
import CertificateForm from '../Forms/CertificateForm.vue';

export default {
	name: 'CertificatesView',
	props: {
		dnsZone: {},
	},
	emits: ['goBack'],
	data() {
		return {
            modalCache: {},
			modalTempData: null,
			certificateList: [],
			certificateTableAllProps: {
				'domains': $gettext('Domains'),
				'expires': $gettext('Expires'),
				'stagingYesNo': $gettext('Staging'),
				'actionRenewHtml': $gettext('Renew'),
				'actionShow': $gettext('Show'),
				'actionDelete': $gettext('Delete')
			},
		};
	},
	async created() {
		this.loadCertificateList();
	},
	beforeUnmount() {
	},
	methods: {
		showCreateCertificateModal() {
			this.modalTempData = {
				newCertificate: {
					domains: [],
					staging: window.certificateStagingAsDefault || false
				}
			};
			this.openModal('createCertificate');
		},
		async download(type) {
			let strippedName = this.dnsZone.name.replace('*', 'wildcard').replace(/\./g, '_');
			let content = '';
			let filename = '';
			if(type == 'cert') {
				content = this.modalTempData.showCertificate.certificateContent;
				filename = "certificate.pem";
			}
			if(type == 'privateKey') {
				content = this.modalTempData.showCertificate.privateKey;
				filename = "privateKey.key";
			}
			let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
			this.saveAs(blob, strippedName+'.'+filename);
		},
		async saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.style = "display: none";
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
			}
		},
		async certificateTableClickHandler(row, prop, event) {
			if(prop == 'actionShow') {
				this.modalTempData = {
					showCertificate: await DnsZoneRessource.certificatesGet(this.dnsZone.id, row.id),
					showCertContent: false,
					showPrivate: false
				};
				this.openModal('showCertificate');
			}

			if(prop == 'actionRenewHtml') {
				if(jQuery(event.target).hasClass('wait') || jQuery(event.target).find('span.wait').length > 0) {
					alert($gettext("This certificate is already marked for renew. Please wait until the renew process is finished. If you want to renew it again, please wait a few minutes and try again."));
					return;
				}
				this.modalTempData = {renewCertificate: row};
				this.openModal('renewConfirm');
			}

			if(prop == 'actionDelete') {
				this.modalTempData = {deleteCertificate: row};
				this.openModal('deletionConfirmCertificate');
			}
		},
		async loadCertificateList() {
			let list = await DnsZoneRessource.certificatesList(this.dnsZone.id);

			this.certificateList = list.map((certificate) => {
				certificate.domainsFlatHtml = '- '+certificate.domains.join("<br/>- ");
				certificate.stagingYesNo = certificate.staging ? $gettext("Yes") : $gettext("No");
				if(certificate.markedForRenew)
					certificate.actionRenewHtml = '<span class="wait">'+$gettext("In process...")+'</span>';
				else
					certificate.actionRenewHtml = $gettext("Renew");
				certificate.actionShow = $gettext("Show");
				certificate.actionDelete = $gettext("Delete");
				return certificate;
			});
		},
		async createCertificate() {
			DnsZoneRessource.certificatesCreate(this.dnsZone.id, this.modalTempData.newCertificate).then(() => {
				this.loadCertificateList();
			});
		},
		async editCertificate() {
			DnsZoneRessource.certificatesUpdate(this.dnsZone.id, this.modalTempData.editCertificate).then(() => {
				this.loadCertificateList();
			});
		},
		async deleteCertificate() {
			DnsZoneRessource.certificatesDelete(this.dnsZone.id, this.modalTempData.deleteCertificate.id).then(() => {
				this.loadCertificateList();
			});
		},
		async renewCertificate() {
			DnsZoneRessource.certificatesRenew(this.dnsZone.id, this.modalTempData.renewCertificate.id).then(() => {
				this.loadCertificateList();
			});
		},
		openModal(id) {
			let element = document.getElementById(id);
			if(!element) return;

			if(!this.modalCache[id])
				this.modalCache[id] = new bootstrap.Modal(element);
			
			this.modalCache[id].show();
			setTimeout(function(){
				jQuery(element).find('input[data-focus-on-modal-open]').focus();
			},500);
		},
		closeModal(id){
			this.modalCache[id].hide();
		},
	},
	computed: {
		createCertificateSubmitDisabled() {
			if(!this.modalTempData || !this.modalTempData.newCertificate) return true;
			if(!this.modalTempData.newCertificate.domains || this.modalTempData.newCertificate.domains.length == 0) return true;
			return false;
		},
	},
	watch: {
	},
	components: {
		RessourceTable,
		BootstrapModal,
		CertificateForm
	},
};

</script>
<style lang="less">

input:focus + span:after, input.form-control:focus + span:after {
	width: 0;
}

.btn + .btn {
	margin-left: 10px;
}

h1 b {
	padding: 3px 10px;
	background-color: #EEE;
	border-radius: 5px;
}

.ressourceTableWrapper {

	td[data-prop="actionRenewHtml"],td[data-prop="actionShow"],td[data-prop="actionDelete"] {
		cursor: pointer;
		color: #c11818;

		span.wait {
			color: #999;
			cursor: progress;
		}
	}
}


</style>