
import { reactive } from 'vue'
import _ from 'underscore';
import axios from 'axios';

export default reactive({

    axiosInterceptorId: null,
    lsKeySettings: 'dnsManagerClient|settings',
    settings: {
        apiKey: '',
        apiUrl: 'https://dnsmanager.invokable.gmbh/api',
    },
   
    initialize() { 
        console.log('GS | initialize');
        this.tryLoadSettings();

        // Configure axios to always set X-API-Key header
        this.axiosInterceptorId = axios.interceptors.request.use((config) => {
            config.headers['X-APIKEY'] = this.settings.apiKey;
            return config;
        });

    },

    tryLoadSettings() {
        console.log('GS | tryLoadSettings');
        let settings = localStorage.getItem(this.lsKeySettings);
        if (settings) {
            this.settings = JSON.parse(settings);
        }
    },

    saveSettings() {
        console.log('GS | saveSettings');
        localStorage.setItem(this.lsKeySettings, JSON.stringify(this.settings));

        // delete old axios interceptor
        axios.interceptors.request.eject(this.axiosInterceptorId);

        // Configure axios to always set new X-API-Key header
        this.axiosInterceptorId = axios.interceptors.request.use((config) => {
            config.headers['X-APIKEY'] = this.settings.apiKey;
            return config;
        });
    },

});
