import ApiConnection from './Connection/ApiConnection.js';
import { reactive } from 'vue';
import GlobalStateManager from '../Components/StateManagers/GlobalStateManager.js';

const doError = (msg) => {
    console.error(msg);
    alert(msg);
};

export default reactive({
    
    async get() {
        window.globalLoader.loading('identity', 'Loading identity');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl);
            window.globalLoader.loadingFinished('identity');
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading identity (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished('identity');
            console.error('Error loading identity (' + e.message + ').');
        }
        return null;
    },

});