import ApiConnection from './Connection/ApiConnection.js';
import { reactive } from 'vue';
import GlobalStateManager from '../Components/StateManagers/GlobalStateManager.js';

const doError = (msg) => {
    console.error(msg);
    alert(msg);
};

export default reactive({
    
    async getServiceStatus() {
        window.globalLoader.loading('service_status', 'Loading service status');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl+'/dns-control/service-status');
            window.globalLoader.loadingFinished('service_status');
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading service status (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished('service_status');
            if(e.response && e.response.data && e.response.data.result) {
                doError('Error loading service status (' + e.response.data.result + ').');
            }
            else
                console.error('Error loading service status (' + e.message + ').');
        }
        return null;
    },
    
    async reload() {
        window.globalLoader.loading('reloadNameserver', 'Reloading Nameserver');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl+'/dns-control/reload');
            window.globalLoader.loadingFinished('reloadNameserver');
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error reloading nameserver (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished('reloadNameserver');
            if(e.response && e.response.data && e.response.data.result) {
                doError('Error reloading nameserver (' + e.response.data.result + ').');
            }
            else
                doError('Error reloading nameserver (' + e.message + ').');
        }
        return null;
    },

});