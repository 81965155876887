import ApiConnection from './Connection/ApiConnection.js';
import { reactive } from 'vue';
import GlobalStateManager from '../Components/StateManagers/GlobalStateManager.js';
import _ from 'underscore';

const doError = (msg) => {
    console.error(msg);
    alert(msg);
};

export default reactive({
    
    async list() {
        let endpoint = '/api-key/list';
        window.globalLoader.loading(endpoint, 'Loading API keys');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading api key list (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading api key list (' + e.message + ').');
        }
        return [];
    },

    async update(apiKey) {
        let endpoint = '/api-key/'+apiKey.id+'/update';
        window.globalLoader.loading(endpoint, 'Updating API key');
        try {
            let params ={
                'name': apiKey.name,
            }
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error updating API key (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error updating API key (' + e.message + ').');
        }
        return [];
    },

    async create(dnsZone) {
        let endpoint = '/api-key/create';
        window.globalLoader.loading(endpoint, 'Creating a new API key');
        try {
            let params = Object.assign({}, dnsZone);
            if(params.id) delete params.id;
            if(params.accessibleZones) delete params.accessibleZones;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error creating api key (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error creating api key (' + e.message + ').');
        }
        return [];
    },

    async connect(apiKey,dnsZoneIds) {
        let endpoint = '/api-key/'+apiKey.id+'/connect';
        window.globalLoader.loading(endpoint, 'Adding zones to API key');
        let formData = new FormData();
        _.each(dnsZoneIds, (dnsZoneId) => {
            formData.append('dnsZoneIds[]', dnsZoneId);
        });
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, formData, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error connecting domain to api key (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error connecting domain to api key (' + e.message + ').');
        }
        return [];
    },

    async disconnect(apiKey,dnsZoneIds) {
        let endpoint = '/api-key/'+apiKey.id+'/disconnect';
        window.globalLoader.loading(endpoint, 'Removing zones from API key');
        let formData = new FormData();
        _.each(dnsZoneIds, (dnsZoneId) => {
            formData.append('dnsZoneIds[]', dnsZoneId);
        });
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, formData, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error disconnecting a domain to api key (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error disconnecting a domain to api key (' + e.message + ').');
        }
        return [];
    },

    async delete(apiKeyId) {
        let endpoint = '/api-key/'+apiKeyId+'/delete';
        window.globalLoader.loading(endpoint, 'Deleting API key');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error deleting api key (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error deleting api key (' + e.message + ').');
        }
        return [];
    },
    
});