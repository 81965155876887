<template>
    <div class="mb-3">
        <label class="form-label">{{ $gettext('Create a new certificate for these domains / hosts:') }}</label>

        <div class="mb-3">
            <ul>
                <li v-for="(host, idx) in this.certificate.domains">
                        <span>{{ host }}</span>
                        <button type="button" class="btn btn-sm btn-danger" @click="this.certificate.domains.splice(idx, 1)">
                            <i class="fas fa-trash"></i>
                        </button>
                </li>
                <li v-if="this.certificate.domains.length == 0">
                    <em>{{ $gettext('Type into the input field below and press enter to add a new entry.') }}</em>
                </li>
            </ul>

            <div class="input-group">
                <input type="text" class="form-control" id="create-host" @keyup.enter="addUrl" v-model="hostToAdd">
                <span class="input-group-addon" style="width: auto; height: auto; display: flex; align-items: center; padding: 0 5px;">.{{ dnsZone.name }}.</span>
            </div>
        </div>
        <div class="mb-3">
            <label for="create-staging" class="form-label">
                <input type="checkbox" class="form-control" style="display: inline-block;" id="create-staging" value="1" v-model="certificate.staging">
                {{ $gettext('Create a test / staging certificate.') }}
            </label>
        </div>
    </div>
</template>

<script>

import _ from 'underscore';

export default {
	name: 'CertificateForm',
	props: {
        dnsZone: {},
        certificate: {},
	},
	data() {
		return {
            hostToAdd: '',
		};
	},
	created() {
	},
	beforeUnmount() {
	},
	methods: {
        addUrl(e) {
            e.preventDefault();

            let toAdd = this.hostToAdd;

            if(toAdd.length == 0) {
                alert($gettext('Hostname cannot be empty. If you want to add the zone domain itself, please just use the "@" sign'));
                return;
            }

            if(toAdd.endsWith('.')) {
                toAdd = toAdd.substring(0, toAdd.length - 1);
            }

            if(toAdd.includes('..')) {
                alert($gettext('You cannot add a host with two dots in a row'));
                return;
            }

            if(toAdd.startsWith('.')) {
                alert($gettext('You cannot add a host that starts with a dot. If you want to have a wildcard, please use a star (*)'));
                return;
            }

            if(toAdd == "@") {
                toAdd = this.dnsZone.name;
            }
            else {
                toAdd = toAdd + '.' + this.dnsZone.name;
            }

            if(_.contains(this.certificate.domains, toAdd)) {
                alert($gettext('This host is already in the list'));
                return;
            }
            this.certificate.domains.push(toAdd);

            this.hostToAdd = '';
        },
	},
	computed: {
	},
	watch: {
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

label {
    font-weight: bold;
}

ul {
    margin: 0;
    padding-left: 17px;

    li {
        list-style-type: square;
        margin-bottom: 5px;

        span + button {
            margin-left: 5px;
        }
    }

}

</style>