<template>
    <form >
        <div class="mb-3">
            <label for="create-type" class="form-label">{{ $gettext('Record type') }}:</label>
            <select class="form-control" id="create-type" v-model="record.type">
                <option value="A">{{ $gettext('A record') }}</option>
                <option value="AAAA">{{ $gettext('AAAA record') }}</option>
                <option value="CNAME">{{ $gettext('CNAME record') }}</option>
                <option value="MX">{{ $gettext('MX record') }}</option>
                <option value="NS">{{ $gettext('NS record') }}</option>
                <option value="TXT">{{ $gettext('TXT record') }}</option>
                <option value="SRV">{{ $gettext('SRV record') }}</option>
                <option value="PTR">{{ $gettext('PTR record') }}</option>
                <option value="CAA">{{ $gettext('CAA record') }}</option>
            </select>
        </div>
        <div class="mb-3">
            <label v-if="isType(['PTR'])" for="create-host" class="form-label">{{ $gettext('IP-Address') }}</label>
            <label v-else-if="isType(['SRV'])" for="create-host" class="form-label">{{ $gettext('Service definition (Format: _<SERVICE_NAME>._<PROTOCOLL>.<DOMAIN_NAME>)') }}</label>
            <label v-else for="create-host" class="form-label">{{ $gettext('Domain / Host') }}</label>

            <input v-if="isType(['PTR'])" type="text" class="form-control" id="create-host" v-model="record.host">
            <div v-else class="input-group">
                <input type="text" class="form-control" id="create-host" v-model="record.host">
                <span class="input-group-addon" style="width: auto; height: auto; display: flex; align-items: center; padding: 0 5px;">.{{ dnsZone.name }}.</span>
            </div>
        </div>
        <div v-if="isType('CAA')" class="mb-3">
            <label for="create-tag" class="form-label">{{ $gettext('Tag') }}</label>
            <select id="create-tag" class="form-control" model="record.tag">
                <option value="issue">{{ $gettext('issue') }}</option>
                <option value="issuewild">{{ $gettext('issuewild') }}</option>
                <option value="iodef">{{ $gettext('iodef') }}</option>
            </select>
        </div>
        <div v-if="!isType('SRV')" class="mb-3">
            <label v-if="isType(['PTR', 'SRV'])" for="create-value" class="form-label">{{ $gettext('Domain / Host') }}</label>
            <label v-else-if="isType(['A', 'AAAA'])" for="create-value" class="form-label">{{ $gettext('IP-Address') }}</label>
            <label v-else-if="isType(['CNAME'])" for="create-value" class="form-label">{{ $gettext('Canonical Name / Alias Name') }}</label>
            <label v-else-if="isType(['NS'])" for="create-value" class="form-label">{{ $gettext('Nameserver') }}</label>
            <label v-else-if="isType(['MX'])" for="create-value" class="form-label">{{ $gettext('Mailserver') }}</label>
            <label v-else for="create-value" class="form-label">{{ $gettext('Value') }}</label>
            <input type="text" class="form-control" id="create-value" v-model="record.value">
        </div>
        <div v-if="isType(['MX','SRV'])" class="mb-3">
            <label for="create-priority" class="form-label">{{ $gettext('Priority') }}</label>
            <select id="create-priority" class="form-control" v-model="record.priority">
                <option value="0">0 {{ $gettext('Highest') }}</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50 {{ $gettext('Lowest') }}</option>
            </select>
        </div>	
        <div v-if="isType(['SRV'])" class="mb-3">
            <label for="create-weight" class="form-label">{{ $gettext('Relative weight for entries of same priority') }}</label>
            <select id="create-weight" class="form-control" v-model="record.weight">
                <option value="10">10 {{ $gettext('Highest') }}</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50 {{ $gettext('Lowest') }}</option>
            </select>
        </div>	
        <div v-if="isType('SRV')" class="mb-3">
            <label for="create-targetHost" class="form-label">{{ $gettext('Target host') }}</label>
            <input type="text" class="form-control" id="create-targetHost" v-model="record.targetHost">
        </div>	
        <div v-if="isType('SRV')" class="mb-3">
            <label for="create-targetPort" class="form-label">{{ $gettext('Target Port') }}</label>
            <input type="number" min="1" max="65535" step="1" class="form-control" id="create-targetPort" v-model="record.targetPort">
        </div>	
        <div class="mb-3" v-if="isType(['A', 'AAAA'])">
            <label for="create-failoverMonitoring" class="form-label">{{ $gettext('Enable Failover-Monitoring?') }}</label>
            <select id="create-failoverMonitoring" class="form-control" v-model="record.failoverMonitoring">
                <option :value="false">{{ $gettext('No') }}</option>
                <option :value="true">{{ $gettext('Yes') }}</option>
            </select>
        </div>	
        <div class="mb-3" v-show="!record.failoverMonitoring">
            <label for="create-ttl" class="form-label">{{ $gettext('TTL') }}</label>
            <input type="number" min="30" max="86400" class="form-control" id="create-ttl" step="1" v-model="record.ttl">
        </div>	
        <template v-if="record.failoverMonitoring">
            <div class="mb-3">
                <label for="create-failover-ip" class="form-label">{{ $gettext('Failover: Backup IP') }}</label>
                <input type="text" class="form-control" id="create-failover-host" v-model="record.failoverHost">
            </div>	
            <div class="mb-3">
                <label for="create-failover-service-to-monitor" class="form-label">{{ $gettext('Failover: Service to monitor') }}</label>
                <select id="create-failover-service-to-monitor" class="form-control" v-model="record.failoverServiceToMonitor">
                    <option value="PING">{{ $gettext('PING') }}</option>
                    <option value="HTTP">{{ $gettext('HTTP (accepts 2xx and 3xx status codes)') }}</option>
                    <option value="HTTPS">{{ $gettext('HTTPS (accepts 2xx and 3xx status codes)') }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="create-failover-mail-for-notification" class="form-label">{{ $gettext('Failover: Mail-Address for notifications') }}</label>
                <input type="email" class="form-control" id="create-failover-for-notification" v-model="record.failoverMailForNotification">
            </div>	
        </template>
    </form>
</template>
	
<script>

import _ from 'underscore';

export default {
	name: 'DnsRecordForm',
	props: {
        dnsZone: {},
        record: {},
	},
	data() {
		return {
		};
	},
	mounted() {
        this.ensureFailoverSettings();
	},
	beforeUnmount() {
	},
	methods: {
		isType(types) {
			if(!Array.isArray(types))
				types = [types];

			for(let i = 0; i < types.length; i++) {
				if(types[i] == this.record.type)
					return true;
			}
			return false;
		},
        ensureFailoverSettings() {
            if(!Object.keys(this.record).includes('failoverMonitoring')) {
                this.record.failoverMonitoring = false;
            }

            if(!this.record.failoverMonitoring) {
                this.record.failoverHost = '';
                this.record.failoverServiceToMonitor = 'PING';
                this.record.failoverMailForNotification = '';
            }
            else {
                this.record.ttl = 30;
            }
        },
	},
	computed: {
	},
	watch: {
        'record.failoverMonitoring'() {
            this.ensureFailoverSettings();
        },
        'record.id'() {
            this.ensureFailoverSettings();
        }
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

</style>