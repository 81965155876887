import ApiConnection from './Connection/ApiConnection.js';
import { reactive } from 'vue';
import GlobalStateManager from '../Components/StateManagers/GlobalStateManager.js';
import _ from 'underscore';

const doError = (msg) => {
    console.error(msg);
    alert(msg);
};

export default reactive({
    
    async list() {
        let endpoint = '/dns-zone/list';
        window.globalLoader.loading(endpoint, 'Loading DNS zones');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading DNS zone list (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading DNS zone list (' + e.message + ').');
        }
        return [];
    },
    
    async get(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/get';
        window.globalLoader.loading(endpoint, 'Loading DNS zone');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading DNS zone (' + e.message + ').');
        }
        return [];
    },

    async healthcheck(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/healthcheck';
        window.globalLoader.loading(endpoint, 'Healthchecking DNS zone');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error Healthchecking DNS Zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error Healthchecking DNS Zone (' + e.message + ').');
        }
        return [];
    },

    async create(dnsZone) {
        let endpoint = '/dns-zone/create';
        window.globalLoader.loading(endpoint, 'Creating a new DNS zone');
        try {
            let params = Object.assign({}, dnsZone);
            if(params.id) delete params.id;
            if(params.dns_records) delete params.dns_records;
            if(params.certificateCount) delete params.certificateCount;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;
            if(params.isAlias) delete params.isAlias;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error creating a DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error creating a DNS zone (' + e.message + ').');
        }
        return [];
    },

    async importZoneFile(name, content, overrideSoaSettings = false, overrideWrongOriginNameservers = false) {
        let endpoint = '/dns-zone/import-zone-file';
        window.globalLoader.loading(endpoint, 'Importing a DNS zone');
        try {
            let params = {
                zonename: name,
                content: content,
                overrideSoaSettings: overrideSoaSettings,
                overrideWrongOriginNameservers: overrideWrongOriginNameservers
            };
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error importing a DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error importing a DNS zone (' + e.message + ').');
        }
        return [];
    },

    async update(dnsZone) {
        let endpoint = '/dns-zone/'+dnsZone.id+'/update';
        window.globalLoader.loading(endpoint, 'Updating DNS zone');
        try {
            let params = Object.assign({}, dnsZone);
            if(params.id) delete params.id;
            if(params.name) delete params.name;
            if(params.dns_records) delete params.dns_records;
            if(params.certificateCount) delete params.certificateCount;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error updating DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error updating DNS zone (' + e.message + ').');
        }
        return [];
    },

    async delete(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/delete';
        window.globalLoader.loading(endpoint, 'Deleting DNS zone');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error deleting DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error deleting DNS zone (' + e.message + ').');
        }
        return [];
    },

    async reload(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/reload';
        window.globalLoader.loading(endpoint, 'Reloading DNS zone');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error reloading DNS zone (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error reloading DNS zone (' + e.message + ').');
        }
        return [];
    },
    
    async getZoneFile(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/export-zone-file';
        window.globalLoader.loading(endpoint, 'Loading zone file');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error loading a DNS zonefile (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading a DNS zonefile (' + e.message + ').');
        }
        return [];
    },

    
    // ***********************
    // DNS Records
    
    async dnsRecordsList(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dns-records/list';
        window.globalLoader.loading(endpoint, 'Loading DNS records');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {                
                doError('Error loading DNS record list (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading DNS record list (' + e.message + ').');
        }
        return [];
    },

    async dnsRecordsCreate(dnsZoneId, dnsRecord) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dns-records/create';
        window.globalLoader.loading(endpoint, 'Creating a new DNS record');
        try {
            let params = Object.assign({}, dnsRecord);
            if(params.id) delete params.id;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error creating a DNS record (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error creating a DNS record (' + e.message + ').');
        }
        return [];
    },

    async dnsRecordsUpdate(dnsZoneId, dnsRecord) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dns-records/'+dnsRecord.id+'/update';
        window.globalLoader.loading(endpoint, 'Creating a new DNS record');
        try {
            let params = Object.assign({}, dnsRecord);
            if(params.id) delete params.id;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error updating a DNS record (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error updating a DNS record (' + e.message + ').');
        }
        return [];
    },

    async dnsRecordsDelete(dnsZoneId, dnsRecordId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dns-records/'+dnsRecordId+'/delete';
        window.globalLoader.loading(endpoint, 'Deleting a DNS record');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error deleting a DNS record (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error deleting a DNS record (' + e.message + ').');
        }
        return [];
    },

    // ***********************
    // Certificates

    async certificatesList(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/certificates/list';
        window.globalLoader.loading(endpoint, 'Loading Certificate list');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {                
                doError('Error loading Certificate list (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading Certificate list (' + e.message + ').');
        }
        return [];
    },


    async certificatesGet(dnsZoneId, certificateId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/certificates/'+certificateId+'/get';
        window.globalLoader.loading(endpoint, 'Loading certificate');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {                
                doError('Error loading certificate (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading certificate (' + e.message + ').');
        }
        return [];
    },

    async certificatesCreate(dnsZoneId, certificate) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/certificates/create';
        window.globalLoader.loading(endpoint, 'Creating a new Certificate');
        try {
            let formData = new FormData();
            _.each(certificate.domains, (domain) => {
                formData.append('domains[]', domain);
            });

            formData.append('staging', certificate.staging);

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, formData, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error creating a Certificate (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error creating a Certificate (' + e.message + ').');
        }
        return [];
    },

    async certificatesRenew(dnsZoneId, dnsRecordId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/certificates/'+dnsRecordId+'/renew';
        window.globalLoader.loading(endpoint, 'Renewing a Certificate');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error Renewing a Certificate (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error Renewing a Certificate (' + e.message + ').');
        }
        return [];
    },

    async certificatesDelete(dnsZoneId, dnsRecordId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/certificates/'+dnsRecordId+'/delete';
        window.globalLoader.loading(endpoint, 'Deleting a Certificate');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error deleting a Certificate (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error deleting a Certificate (' + e.message + ').');
        }
        return [];
    },

    // ***********************
    // Dyndns Accounts

    async dyndnsAccountsList(dnsZoneId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dyndns-accounts/list';
        window.globalLoader.loading(endpoint, 'Loading Dyndns Account list');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {                
                doError('Error loading Dyndns Account list (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading Dyndns Account list (' + e.message + ').');
        }
        return [];
    },


    async dyndnsAccountsGet(dnsZoneId, certificateId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dyndns-accounts/'+certificateId+'/get';
        window.globalLoader.loading(endpoint, 'Loading Dyndns Account');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {                
                doError('Error loading Dyndns Account (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error loading Dyndns Account (' + e.message + ').');
        }
        return [];
    },

    async dyndnsAccountsCreate(dnsZoneId, dnsRecord) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dyndns-accounts/create';
        window.globalLoader.loading(endpoint, 'Creating a new Dyndns Account');
        try {
            let params = Object.assign({}, dnsRecord);
            if(params.id) delete params.id;
            if(params.actionEdit) delete params.actionEdit;
            if(params.actionDelete) delete params.actionDelete;

            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint, params, 'POST');
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error creating a Dyndns Account (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error creating a Dyndns Account (' + e.message + ').');
        }
        return [];
    },

    async dyndnsAccountsDelete(dnsZoneId, dnsRecordId) {
        let endpoint = '/dns-zone/'+dnsZoneId+'/dyndns-accounts/'+dnsRecordId+'/delete';
        window.globalLoader.loading(endpoint, 'Deleting a Dyndns Account');
        try {
            let result = await ApiConnection(GlobalStateManager.settings.apiUrl + endpoint);
            window.globalLoader.loadingFinished(endpoint);
            if(result.status == "success") {
                return result.result;
            }
            else {
                doError('Error deleting a Dyndns Account (' + result.result + ').');
            }
        }
        catch(e) {
            window.globalLoader.loadingFinished(endpoint);
            doError('Error deleting a Dyndns Account (' + e.message + ').');
        }
        return [];
    },
    
});