<template>
	<div :class="['dns-records-view']">
		<h1>{{ $gettext('Manage DNS Zone') }} <b>{{ dnsZone.name }}</b></h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="$emit('goBack')">{{ $gettext('Back to list') }}</a>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showCreateRecordModal">{{ $gettext('Create new DNS Record') }}</a>
		<a class="btn btn-secondary" href="javascript:void(0)" @click="reloadZone">{{ $gettext('Reload Zone') }}</a>
		<RessourceTable :class="{'dnsRecordTable': true}" :dataRows="recordList" :allProps="dnsRecordTableAllProps" @click="dnsRecordTableClickHandler" />
	</div>
	<BootstrapModal id="createDnsRecord" modalSize="large" :title=" $gettext('Create a new DNS Record') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="createDnsRecord()">
		<DnsRecordForm v-if="modalTempData && modalTempData.newRecord" :dnsZone="dnsZone" :record="modalTempData.newRecord" />
	</BootstrapModal>
	<BootstrapModal id="editDnsRecord" modalSize="large" :title=" $gettext('Edit this DNS Record') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="editDnsRecord()">
		<DnsRecordForm v-if="modalTempData && modalTempData.editRecord" :dnsZone="dnsZone" :record="modalTempData.editRecord" />
	</BootstrapModal>
	<BootstrapModal id="deletionConfirmDnsRecord" :title="$gettext('Delete?')" :uiCloseVisible="true" :submitTitle="$gettext('Delete')" @submit="deleteDnsRecord()">
		{{ $gettext('Are you sure you want to delete this DNS record?') }}
	</BootstrapModal>
</template>

<script>

import _ from 'underscore';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';
import DnsRecordForm from '../Forms/DnsRecordForm.vue';

export default {
	name: 'DnsRecordsView',
	props: {
		dnsZone: {},
	},
	emits: ['goBack'],
	data() {
		return {
            modalCache: {},
			modalTempData: null,
			recordList: [],
			dnsRecordTableAllProps: {
				'host': 'Host',
				'type': 'Type',
				'value': 'Value',
				'ttl': 'TTL',
				'opt': 'Optional Parameters',
				'actionEdit': 'Edit',
				'actionDelete': 'Delete'
			},
		};
	},
	async created() {
		this.loadRecordList();
	},
	beforeUnmount() {
	},
	methods: {
		showCreateRecordModal() {
			this.modalTempData = {
				newRecord: {
					type: 'A',
					host: '',
					value: '',
					ttl: 60,
					tag: '',
					priority: 0,
					weight: 10,
					targetHost: '',
					targetPort: 80,
					failoverMonitoring: false,
					failoverState: null,
					failoverHost: '',
					failoverServiceToMonitor: 'PING',
					failoverMailForNotification: ''
				}
			};
			this.openModal('createDnsRecord');
		},
		dnsRecordTableClickHandler(row, prop, event) {
			if(prop == 'actionEdit') {
				this.modalTempData = {editRecord: _.clone(row)};
				this.openModal('editDnsRecord');
			}

			if(prop == 'actionDelete') {
				this.modalTempData = {deleteRecord: row};
				if(event.shiftKey)
					this.deleteDnsRecord();
				else
					this.openModal('deletionConfirmDnsRecord');
			}
		},
		async loadRecordList() {
			let recorList = await DnsZoneRessource.dnsRecordsList(this.dnsZone.id);

			this.recordList = recorList.map((record) => {
				record.opt = "";
				if(record.tag) record.opt += "tag=" + record.tag + " ";
				if(record.priority) record.opt += "priority=" + record.priority + " ";
				if(record.weight) record.opt += "weight=" + record.weight + " ";
				if(record.targetHost) record.opt += "targetHost=" + record.targetHost + " ";
				if(record.targetPort) record.opt += "targetPort=" + record.targetPort + " ";
				if(record.failoverMonitoring) record.opt += "failover=" + record.failoverHost + "(" + record.failoverServiceToMonitor + ", State: " + (record.failoverState ? record.failoverState : 'Waiting for first check...') + ") ";
				record.actionEdit = $gettext("Edit");
				record.actionDelete = $gettext("Delete");
				return record;
			});
		},
		async createDnsRecord() {
			DnsZoneRessource.dnsRecordsCreate(this.dnsZone.id, this.modalTempData.newRecord).then(() => {
				this.loadRecordList();
			});
		},
		async reloadZone() {
			DnsZoneRessource.reload(this.dnsZone.id).then(() => {});
		},
		async editDnsRecord() {
			DnsZoneRessource.dnsRecordsUpdate(this.dnsZone.id, this.modalTempData.editRecord).then(() => {
				this.loadRecordList();
			});
		},
		async deleteDnsRecord() {
			DnsZoneRessource.dnsRecordsDelete(this.dnsZone.id, this.modalTempData.deleteRecord.id).then(() => {
				this.loadRecordList();
			});
		},
		openModal(id) {
			let element = document.getElementById(id);
			if(!element) return;

			if(!this.modalCache[id])
				this.modalCache[id] = new bootstrap.Modal(element);
			
			this.modalCache[id].show();
			setTimeout(function(){
				jQuery(element).find('input[data-focus-on-modal-open]').focus();
			},500);
		},
		closeModal(id){
			this.modalCache[id].hide();
		},
	},
	computed: {
	},
	watch: {
	},
	components: {
		RessourceTable,
		BootstrapModal,
		DnsRecordForm
	},
};

</script>
<style lang="less">

input:focus + span:after, input.form-control:focus + span:after {
	width: 0;
}

.btn + .btn {
	margin-left: 10px;
}

h1 b {
	padding: 3px 10px;
	background-color: #EEE;
	border-radius: 5px;
}

.ressourceTableWrapper {

	td[data-prop="actionEdit"],td[data-prop="actionDelete"] {
		cursor: pointer;
		color: #c11818;
	}
}


</style>