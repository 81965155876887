<template>
	<div :class="['system-status-view']">
		<h1>{{ $gettext('System status') }}</h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="loadServiceStatus">{{ $gettext('Refresh this page') }}</a>
		<a class="btn btn-secondary" href="javascript:void(0)" @click="reloadNameserver">{{ $gettext('Reload nameserver') }}</a>
		<br/><br/>
		<template v-if="this.serviceStatus">
			<h2>{{ $gettext('Systemctl service status') }}</h2>
			<pre v-html="this.serviceStatus.systemCtlServiceStatus.join('\n')"></pre>
			<br/>
			<h2>{{ $gettext('Nameserver status') }}</h2>
			<pre v-html="this.serviceStatus.namedStatus.join('\n')"></pre>
			<br/>
			<h2>{{ $gettext('named-checkconf') }}</h2>
			<pre v-html="this.serviceStatus.namedCheckconf.join('\n')"></pre>
			<br/>
			<h2>{{ $gettext('Logs') }}</h2>
			<RessourceTable v-if="this.serviceStatus.logLines" :class="{'logTable': true}" :dataRows="parsedLogLines" :allProps="logLineProps" />
		</template>
	</div>
</template>

<script>

import _ from 'underscore';
import DnsControlRessource from '../../ApiRessource/DnsControlRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';

export default {
	name: 'SystemStatusView',
	data() {
		return {
            serviceStatus: null,
			logLineProps: {
				"timestamp": $gettext('Timestamp'),
				"hostname": $gettext('Hostname'),
				"service": $gettext('Service'),
				"text": $gettext('Text'),
			},
		};
	},
	async created() {
		this.loadServiceStatus();
	},
	beforeUnmount() {
	},
	methods: {
		async loadServiceStatus() {
			this.serviceStatus = await DnsControlRessource.getServiceStatus();
		},
		async reloadNameserver() {
			let that = this;
			await DnsControlRessource.reload();
			setTimeout(function(){that.loadServiceStatus()}, 5000);
		},
	},
	computed: {
		parsedLogLines() {
			if (!this.serviceStatus || !this.serviceStatus.logLines) {
				return [];
			}
			return this.serviceStatus.logLines.map((logLine) => {
				// split timestamp, hostname, service and text
				let timestamp = logLine.substring(0, 15);
				let restOfLine = logLine.substring(16);
				let hostname = restOfLine.substring(0, restOfLine.indexOf(' '));
				let restOfLine2 = restOfLine.substring(restOfLine.indexOf(' ') + 1);
				let service = restOfLine2.substring(0, restOfLine2.indexOf(' '));
				let text = restOfLine2.substring(restOfLine2.indexOf(' ') + 1);

				// remove last ":" from service
				if (service.endsWith(':')) {
					service = service.substring(0, service.length - 1);
				}							
				
				return {
					timestamp: timestamp,
					hostname: hostname,
					service: service,
					text: text,
				};
			});
		},
	},
	watch: {
	},
	components: {
		RessourceTable,
		BootstrapModal
	},
};

</script>
<style lang="less">


</style>