<template>
	<div :class="allClasses" :id="id" tabindex="-1" role="dialog" :aria-labelledby="titleAriaLabel" aria-hidden="true">
		<div :class="innerClasses">
			<div class="modal-content modal-header-colored shadow-lg border-0">
				<div class="modal-header p-3 m-0">
					<h5 class="modal-title font-size-16" :id="titleAriaLabel">
						{{ title }}
					</h5>
					<button v-if="uiCloseIconVisible" type="button" class="btn-close" data-bs-dismiss="modal"
						aria-label="Close" @click="closeModal">
					</button>
				</div>
				<div class="modal-body p-3">
					<slot></slot>
				</div>
				<div class="modal-footer pt-1 pb-1 ps-2 pe-2"  v-if="uiCloseVisible || (submitTitle && uiSubmitVisible)">
					<button type="button" class="btn btn-outline-primary btn-small" v-if="uiCloseVisible" data-bs-dismiss="modal"
						@click="closeModal">
						{{ closeTitle }}
					</button>
					<button type="button" class="btn btn-primary btn-small" v-if="submitTitle && uiSubmitVisible"
						:data-bs-dismiss="closeOnSubmit ? 'modal' : null" @click="submitModal" :disabled="submitDisabled">
						{{ submitTitle }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		id: {},
		title: {},
		submitTitle: {},
		closeTitle: {
			default: $gettext('Close')
		},
		closeOnSubmit: {
			default: true,
		},
		submitDisabled: {
			default: false,
		},
		additionalClasses: "",
		modalSize: "",
		uiCloseVisible: {
			default: true,
		},
		uiCloseIconVisible: {
			default: true,
		},
		uiSubmitVisible: {
			default: true,
		}
	},
	data: function () {
		return {
		}
	},
	computed: {
		titleAriaLabel() {
			return `${this.id}ModalLabel`;
		},
		allClasses() {
			return "modal fade " + (this.additionalClasses || '');
		},
		innerClasses() {
			let classes = "modal-dialog modal-dialog-centered justify-content-center";
			if (this.modalSize == "large")
				classes += ' modal-lg';
			if(this.modalSize == "medium")
				classes += ' modal-md';
			if (this.modalSize == "small")
				classes += ' modal-sm';
			return classes;
		}
	},
	methods: {
		closeModal() {
			this.$emit('close');
		},
		submitModal() {
			if (this.closeOnSubmit) this.$emit('close');
			this.$emit('submit');
		}
	}
};
</script>

<style lang="less" scoped>

</style>
