<template>
	<div :class="['dyndns-accounts-view']">
		<h1>{{ $gettext('Manage Dyndns-Accounts for DNS Zone') }} <b>{{ dnsZone.name }}</b></h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="$emit('goBack')">{{ $gettext('Back to list') }}</a>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showCreateDyndnsAccountModal">{{ $gettext('Create new Dyndns Account') }}</a>
		<RessourceTable :class="{'dyndnsaccountTable': true}" :dataRows="dyndnsaccountList" :allProps="dyndnsaccountTableAllProps" @click="dyndnsaccountTableClickHandler" />
	</div>
	<BootstrapModal id="createDyndnsAccount" modalSize="large" :title=" $gettext('Create a new Dyndns Account') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="createDyndnsAccount()">
		<DyndnsAccountForm v-if="modalTempData && modalTempData.newDyndnsAccount" :dnsZone="dnsZone" :dyndnsAccount="modalTempData.newDyndnsAccount" />
	</BootstrapModal>
	<BootstrapModal id="showDyndnsAccount" modalSize="large" :title=" $gettext('Show Dyndns Account') " :uiCloseVisible="true" :uiSubmitVisible="false" >
		<template v-if="this.modalTempData && Object.keys(this.modalTempData).includes('showDyndnsAccount')">
			<p>
				{{ $gettext('You can use this dyndns account to let you router automatically update this domain name: ') }} <span class="highlightedText">{{ modalTempData.showDyndnsAccount.host + '.' + dnsZone.name }}</span>
				<br/>
				{{ $gettext('The DNS records of the dyndns host will only appear inside the actual zone file. They will NOT be visible as regular dns records for the zone via the api endpoints.') }}
				<br/>
			</p>
			<p>
				{{ $gettext('The concrete settings you have to enter into your device depend on the model of your router. They should be very similar to the following values, which show how to connect with an AVM Fritz!Box.') }}
				{{ $gettext('Open the settings of your Fritz!Box and navigate to Internet > Permit access > DynDNS.') }}
			</p>
			<table class="popupTable table-striped">
				<tr>
					<th>{{ $gettext('Update URL') }}</th>
					<td><pre @click="autocopy" class="autocopy">{{ this.getUpdateUrl() }}</pre></td>
				</tr>
				<tr>
					<th>{{ $gettext('Domain name') }}</th>
					<td>
						<pre @click="autocopy" class="autocopy">{{ modalTempData.showDyndnsAccount.host + '.' + dnsZone.name }}</pre>
						<small>{{ $gettext('The value of this parameter is irrelevant for the dns manager system, as your credentials are bound to a specific fqdn.') }}</small>
					</td>
				</tr>
				<tr>
					<th>{{ $gettext('Username') }}</th>
					<td><pre @click="autocopy" class="autocopy">{{ modalTempData.showDyndnsAccount.id }}</pre></td>
				</tr>
				<tr>
					<th>{{ $gettext('Password') }}</th>
					<td><pre @click="autocopy" class="autocopy">{{ modalTempData.showDyndnsAccount.basicAuthPassword }}</pre></td>
				</tr>
			</table>
			<br/>
			<div class="lastActivation">
				<strong style="text-decoration: underline;">{{ $gettext('Data of last update') }}</strong><br/>
				<table class="popupTable table-striped">
					<tr>
						<th>{{ $gettext('IP address') }}</th>
						<td><pre @click="autocopy" class="autocopy">{{ joinedIps }}</pre></td>
					</tr>
					<tr>
						<th>{{ $gettext('Last update at') }}</th>
						<td><pre @click="autocopy" class="autocopy">{{ modalTempData.showDyndnsAccount.lastUpdateAt ? modalTempData.showDyndnsAccount.lastUpdateAt : 'NOT SET' }}</pre></td>
					</tr>
					<tr>
						<th>{{ $gettext('Last used useragent') }}</th>
						<td><pre @click="autocopy" class="autocopy">{{ modalTempData.showDyndnsAccount.lastUsedUserAgent ? modalTempData.showDyndnsAccount.lastUsedUserAgent : 'NOT SET' }}</pre></td>
					</tr>
				</table>
			</div>
		</template>
	</BootstrapModal>
	<BootstrapModal id="deletionConfirmDyndnsAccount" :title="$gettext('Delete?')" :uiCloseVisible="true" :submitTitle="$gettext('Delete')" @submit="deleteDyndnsAccount()">
		{{ $gettext('Are you sure you want to delete this DyndnsAccount?') }}
	</BootstrapModal>
</template>

<script>

import _ from 'underscore';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';
import DyndnsAccountForm from '../Forms/DyndnsAccountForm.vue';

export default {
	name: 'DyndnsAccountsView',
	props: {
		dnsZone: {},
	},
	emits: ['goBack'],
	data() {
		return {
            modalCache: {},
			modalTempData: null,
			dyndnsaccountList: [],
			dyndnsaccountTableAllProps: {
				'name': $gettext('Name'),
				'host': $gettext('Host'),
				'actionShow': $gettext('Show'),
				'actionDelete': $gettext('Delete')
			},
		};
	},
	async created() {
		this.loadDyndnsAccountList();
	},
	beforeUnmount() {
	},
	methods: {
		showCreateDyndnsAccountModal() {
			this.modalTempData = {
				newDyndnsAccount: {
					name: "",
					host: ""
				}
			};
			this.openModal('createDyndnsAccount');
		},
		async autocopy(event) {
			await navigator.clipboard.writeText(event.target.innerText);
			event.target.classList.add('copied');
			setTimeout(() => {
				event.target.classList.remove('copied');
			}, 1000);
		},
		async dyndnsaccountTableClickHandler(row, prop, event) {
			if(prop == 'actionShow') {
				this.modalTempData = {
					showDyndnsAccount: await DnsZoneRessource.dyndnsAccountsGet(this.dnsZone.id, row.id),
					showCertContent: false,
					showPrivate: false
				};
				this.openModal('showDyndnsAccount');
			}

			if(prop == 'actionDelete') {
				this.modalTempData = {deleteDyndnsAccount: row};
				this.openModal('deletionConfirmDyndnsAccount');
			}
		},
		async loadDyndnsAccountList() {
			let list = await DnsZoneRessource.dyndnsAccountsList(this.dnsZone.id);

			this.dyndnsaccountList = list.map((dyndnsaccount) => {
				dyndnsaccount.actionShow = $gettext("Show");
				dyndnsaccount.actionDelete = $gettext("Delete");
				return dyndnsaccount;
			});
		},
		async createDyndnsAccount() {
			DnsZoneRessource.dyndnsAccountsCreate(this.dnsZone.id, this.modalTempData.newDyndnsAccount).then(() => {
				this.loadDyndnsAccountList();
			});
		},
		async editDyndnsAccount() {
			DnsZoneRessource.dyndnsAccountsUpdate(this.dnsZone.id, this.modalTempData.editDyndnsAccount).then(() => {
				this.loadDyndnsAccountList();
			});
		},
		async deleteDyndnsAccount() {
			DnsZoneRessource.dyndnsAccountsDelete(this.dnsZone.id, this.modalTempData.deleteDyndnsAccount.id).then(() => {
				this.loadDyndnsAccountList();
			});
		},
		openModal(id) {
			let element = document.getElementById(id);
			if(!element) return;

			if(!this.modalCache[id])
				this.modalCache[id] = new bootstrap.Modal(element);
			
			this.modalCache[id].show();
			setTimeout(function(){
				jQuery(element).find('input[data-focus-on-modal-open]').focus();
			},500);
		},
		closeModal(id){
			this.modalCache[id].hide();
		},
		getUpdateUrl() {
			// Get current hostname
			let hostname = window.location.hostname;
			let updateUrl = 'https://' + hostname + '/nic/update?myip=<ipaddr>,<ip6addr>&hostname=<domain>';
			return updateUrl;
		}
	},
	computed: {
		joinedIps() {
			if(this.modalTempData.showDyndnsAccount.ips) {
				if(Array.isArray(this.modalTempData.showDyndnsAccount.ips)) {
					return this.modalTempData.showDyndnsAccount.ips.join(', ');
				}
			}
			return 'NOT SET';
		}
	},
	watch: {
	},
	components: {
		RessourceTable,
		BootstrapModal,
		DyndnsAccountForm
	},
};

</script>
<style lang="less">

.highlightedText {
	background-color: #EEE;
	padding: 2px 5px;
	border-radius: 3px;
}

input:focus + span:after, input.form-control:focus + span:after {
	width: 0;
}

.btn + .btn {
	margin-left: 10px;
}

h1 b {
	padding: 3px 10px;
	background-color: #EEE;
	border-radius: 5px;
}

.ressourceTableWrapper {
	td[data-prop="actionShow"],td[data-prop="actionDelete"] {
		cursor: pointer;
		color: #c11818;
	}
}

table.popupTable {
	th {
		width: 15%;
		vertical-align: top;
	}

	td {
		width: 85%;
		vertical-align: top;

		pre {
			width: 85%;
			background-color: transparent;
			border: none;
			font-size: 0.8em;
			padding: 2px 0;
			margin: 0;
			color: #333;

			&.autocopy {
				margin: 0;
				cursor: copy;
				position: relative;

				&:hover {
					background-color: rgba(0,0,0,0.1);
				}

				&.copied {
					background-color: rgba(111, 181, 221, 0.719);

					&::after {
						content: "Copied!";
						font-size: 1.5em;
						color: #333;
						background-color: #c11818;
						color: #FFF;
						border-radius: 5px;
						padding: 0px 10px;
						font-size: 0.8em;
						margin-left: 10px;
						position: absolute;
						right: 0;
						z-index: 9999999;
					}
				}
			}
		}
	}
}

.lastActivation {
	background-color: #c4d7f3;
	padding: 10px;
	border-radius: 5px;

	table tr {
		border-bottom: 1px solid #FFF;

		th {
			width: 30%;
		}

		td {
			width: 70%;
		}

		&:last-child {
			border-bottom: none;
		}
	}
}

</style>