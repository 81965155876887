<template>
	<aside :class="['sidebar']">
		<div class="user" v-if="identity">
			<strong>{{ identity.authenticatedApiKey }}</strong>
			<small>({{ identity.authenticatedApiKeyRole }})</small>
		</div>
		<div class="user" v-else>
			<strong>{{ $gettext('Please authenticate via settings tab!') }}</strong>
		</div>
		<ul>
			<li :class="{ active: currentView == 'settings' }">
				<a href="#settings" @click="switchView('settings')">Settings</a>
			</li>
			<li v-if="identity" :class="{ active: currentView == 'dns-zones' }">
				<a href="#dns-zones" @click="switchView('dns-zones')">DNS Zones</a>
			</li>
			<li v-if="identity && identity.authenticatedApiKeyRole == 'superadmin'" :class="{ active: currentView == 'api-keys' }">
				<a href="#api-keys" @click="switchView('api-keys')">API Keys</a>
			</li>
			<li v-if="identity && identity.authenticatedApiKeyRole == 'superadmin'" :class="{ active: currentView == 'system-status' }">
				<a href="#system-status" @click="switchView('system-status')">System status</a>
			</li>
		</ul>
	</aside>
</template>

<script>

import _ from 'underscore';
import IdentityRessource from '../../ApiRessource/IdentityRessource';
import GlobalStateManager from '../StateManagers/GlobalStateManager.js';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';

export default {
	name: 'Sidebar',
	props: {
		currentView: {
			type: String,
			default: 'settings'
		}
	},
	data() {
		return {
			identity: null,
			identityLoaderDebounce: null
		};
	},
	async created() {
		// Validate hash
		let foundRoute = null;
		let dnsZone = null;

		if(window.location.hash && _.contains(['settings','dns-zones','api-keys','system-status'], window.location.hash.replace('#',''))) {
			foundRoute = window.location.hash.replace('#','');
		}

		if(window.location.hash.startsWith('#dns-records/')) {
			foundRoute = 'dns-records';
			dnsZone = await DnsZoneRessource.get(window.location.hash.replace('#dns-records/',''));
		}

		if(window.location.hash.startsWith('#certificates/')) {
			foundRoute = 'certificates';
			dnsZone = await DnsZoneRessource.get(window.location.hash.replace('#certificates/',''));
		}

		if(window.location.hash.startsWith('#dyndns-accounts/')) {
			foundRoute = 'dyndns-accounts';
			dnsZone = await DnsZoneRessource.get(window.location.hash.replace('#dyndns-accounts/',''));
		}

		if(!foundRoute) {
			foundRoute = 'settings';
		}

		this.$emit('switchView', {
			dnsZone: dnsZone,
			view: foundRoute
		});
		this.loadIdentity();
	},
	beforeUnmount() {
	},
	methods: {
		async loadIdentity() {
			this.identity = await IdentityRessource.get();
		},
		async switchView(name) {
			this.$emit('switchView', {
				dnsZone: null,
				view: name
			});
		}
	},
	computed: {
		GlobalStateManager: {
			get() {
				return GlobalStateManager;
			}
		}
	},
	watch: {
		'GlobalStateManager.settings': {
			handler: function() {
				clearTimeout(this.identityLoaderDebounce);
				this.identityLoaderDebounce = setTimeout(() => {
					this.loadIdentity();
				}, 1000);
			},
			deep: true
		}
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

	aside {
		border-left: 1px solid #666;
    	margin-left: 5px;
		height: 100vh;
		width: 100%;
		padding: 20px;

		.user {
			background-color: #EEE;
			padding: 5px;
			margin: 5px;

			strong {
				margin-right: 5px;
			}
		}

		> ul {
			padding-left: 10px;
		}

		ul li {

			list-style: none;

			a {
				display: block;
				background-color: #EEE;
				padding: 5px;
				margin: 5px;
			}

			&.active a {
				background-color: #CCC;
				font-weight: bold;
			}
		}
	}

	


</style>