<template>
    <div class="mb-3">
        <label class="form-label">{{ $gettext('Create a new dyndns account:') }}</label>

        <div class="mb-3">
            <label for="create-name" class="form-label">{{ $gettext('Name / Beschreibung') }}</label>
            <input type="text" class="form-control" id="create-name" v-model="dyndnsAccount.name">
        </div>

        <div class="mb-3">
            <label for="create-host" class="form-label">{{ $gettext('Domain / Host') }}</label>

            <div class="input-group">
                <input type="text" class="form-control" id="create-host" v-model="dyndnsAccount.host">
                <span class="input-group-addon" style="width: auto; height: auto; display: flex; align-items: center; padding: 0 5px;">.{{ dnsZone.name }}.</span>
            </div>
        </div>
    </div>
</template>

<script>

import _ from 'underscore';

export default {
	name: 'DyndnsAccountForm',
	props: {
        dnsZone: {},
        dyndnsAccount: {},
	},
	data() {
		return {
		};
	},
	created() {
	},
	beforeUnmount() {
	},
	methods: {
	},
	computed: {
	},
	watch: {
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

</style>