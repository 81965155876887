<template>
	<div :class="['dns-manager-client']">
		
		<div class="row">

            <div class="col-lg-9 col-12 mb-5 fullscreen-page-left--content">
                <SettingsView v-if="currentView == 'settings'" />
				<DnsZoneView v-if="currentView == 'dns-zones'" @switchView="switchView" />
				<DnsRecordsView v-if="currentView == 'dns-records' && selectedDnsZone" :dnsZone="this.selectedDnsZone" @goBack="this.selectedDnsZone = null; this.currentView = 'dns-zones'"/>
				<CertificatesView v-if="currentView == 'certificates' && selectedDnsZone" :dnsZone="this.selectedDnsZone" @goBack="this.selectedDnsZone = null; this.currentView = 'dns-zones'"/>
				<DyndnsAccountsView v-if="currentView == 'dyndns-accounts' && selectedDnsZone" :dnsZone="this.selectedDnsZone" @goBack="this.selectedDnsZone = null; this.currentView = 'dns-zones'"/>
				<ApiKeysView v-if="currentView == 'api-keys'" />
				<SystemStatusView v-if="currentView == 'system-status'" />
            </div>

            <div class="col-lg-3 d-none d-lg-block fullscreen-page-right--content">
                <sidebar :currentView="currentView" @switchView="switchView" />
            </div>

        </div>

	</div>
</template>

<script>

import SettingsView from '../Views/SettingsView.vue';
import DnsZoneView from '../Views/DnsZoneView.vue';
import DnsRecordsView from '../Views/DnsRecordsView.vue';
import ApiKeysView from '../Views/ApiKeysView.vue';
import Sidebar from '../Menus/Sidebar.vue';
import GlobalStateManager from '../StateManagers/GlobalStateManager.js';
import CertificatesView from '../Views/CertificatesView.vue';
import DyndnsAccountsView from '../Views/DyndnsAccountsView.vue';
import SystemStatusView from '../Views/SystemStatusView.vue';

export default {
	name: 'DnsManagerClient',
	data() {
		return {
			currentView: 'settings',
			selectedDnsZone: null,
		};
	},
	created() {
		GlobalStateManager.initialize();
	},
	beforeUnmount() {
	},
	methods: {
		switchView($event) {
			this.selectedDnsZone = $event.dnsZone;
			this.currentView = $event.view

			if(this.selectedDnsZone) {
				window.location.hash = this.currentView + '/' + this.selectedDnsZone.id;
			} else {
				window.location.hash = this.currentView;
			}
		}
	},
	computed: {
	},
	watch: {
	},
	components: {
    SettingsView,
    DnsZoneView,
    DnsRecordsView,
    ApiKeysView,
    Sidebar,
    CertificatesView,
	DyndnsAccountsView,
    SystemStatusView
},
};

</script>
<style lang="less" scoped>

.fullscreen-page-left--content {
	height: 100vh;
	overflow-y: auto;
	padding: 20px;
}

</style>