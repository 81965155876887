<template>
	<div :class="['api-keys-view']">
		<h1>{{ $gettext('Manage API Keys') }}</h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showCreateKeyModal">{{ $gettext('Create new API Key') }}</a>
		<RessourceTable :class="{'keyTable': true}" :dataRows="keyList" :allProps="keyTableAllProps" @click="keyTableClickHandler" />
	</div>
	<BootstrapModal id="createApiKey" modalSize="large" :title=" $gettext('Create a new API key') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="createApiKey()">
		<!-- Bootstrap form -->
		<form v-if="modalTempData && modalTempData.newApiKey">
			<div class="mb-3">
				<label for="create-name" class="form-label">{{ $gettext('Name') }}</label>
				<input type="text" class="form-control" id="create-name" v-model="modalTempData.newApiKey.name">
			</div>	
		</form>
	</BootstrapModal>
	<BootstrapModal id="editApiKey" modalSize="large" :title=" $gettext('Rename this API key') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="editApiKey()">
		<!-- Bootstrap form -->
		<form v-if="modalTempData && modalTempData.editApiKey">
			<div class="mb-3">
				<label for="edit-name" class="form-label">{{ $gettext('Name') }}</label>
				<input type="text" class="form-control" id="edit-name" v-model="modalTempData.editApiKey.name">
			</div>	
		</form>
	</BootstrapModal>
	<BootstrapModal id="manageApiKeyZones" modalSize="large" :title=" $gettext('Accessible zones of API key') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="editAccessibleZones()">
		<RessourceTable v-if="modalTempData && modalTempData.accessibleZones" :class="{'accessibleZoneTable': true}" :dataRows="modalTempData.accessibleZones" :allProps="accessibleZonesAllProps" @click="accessibleZonesTableClickHandler" />
		<form>
			<div v-if="this.addableZoneList && this.addableZoneList.length" class="mt-3">
				<label for="manageApiKeyZones-addZone" class="form-label">{{ $gettext('Add zone') }}:</label>
				<select class="form-control" id="manageApiKeyZones-addZone" v-model="zoneToAdd">
					<option v-for="zone in this.addableZoneList" :value="zone.id">{{ zone.name }}</option>
				</select>
			</div>
		</form>
	</BootstrapModal>
	<BootstrapModal id="deletionConfirmApiKey" :title="$gettext('Delete?')" :uiCloseVisible="true" :submitTitle="$gettext('Delete')" @submit="deleteApiKey()">
		{{ $gettext('Are you sure you want to delete this API key?') }}
	</BootstrapModal>
</template>

<script>

import _ from 'underscore';
import ApiKeyRessource from '../../ApiRessource/ApiKeyRessource';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';

export default {
	name: 'ApiKeysView',
	data() {
		return {
            modalCache: {},
			modalTempData: null,
			keyList: [],
			zoneToAdd: null,
			addableZoneList: [],
			keyTableAllProps: {
				'id': $gettext('Key'),
				'name': $gettext('Name'),
				'role': $gettext('Role'),
				'accessibleZonesCount': $gettext('Accessible Zones'),
				'actionEdit': $gettext('Edit'),
				'actionDelete': $gettext('Delete')
			},
			accessibleZonesAllProps: {
				'name': $gettext('Name'),
				'actionDelete': $gettext('Delete')
			},
		};
	},
	async created() {
		this.loadKeyList();
	},
	beforeUnmount() {
	},
	methods: {
		showCreateKeyModal() {
			this.modalTempData = {
				newApiKey: {
					name: ''
				}
			};
			this.openModal('createApiKey');
		},
		keyTableClickHandler(row, prop, event) {
			if(prop == 'accessibleZonesCount') {
				this.modalTempData = {apiKeyToManageAccessibleZones: row, accessibleZones: this.accessibleZonesForModal(row.accessibleZones)};
				this.loadAddableZones();
				this.openModal('manageApiKeyZones');
			}

			if(prop == 'actionEdit') {
				this.modalTempData = {editApiKey: row};
				this.openModal('editApiKey');
			}

			if(prop == 'actionDelete') {
				this.modalTempData = {deleteApiKey: row};
				this.openModal('deletionConfirmApiKey');
			}
		},
		accessibleZonesTableClickHandler(row, prop, event) {
			if(prop == 'actionDelete') {
				this.modalTempData.accessibleZones = this.modalTempData.accessibleZones.filter((zone) => {
					return zone.id != row.id;
				});
				this.loadAddableZones();
			}
		},
		async loadKeyList() {
			let keyList = await ApiKeyRessource.list();

			this.keyList = keyList.map((key) => {
				key.accessibleZonesCount = key.accessibleZones ? Object.keys(key.accessibleZones).length : 0;
				key.accessibleZonesCount = key.accessibleZonesCount + ' (Manage)';
				key.actionEdit = $gettext("Rename");
				key.actionDelete = $gettext("Delete");
				return key;
			});

		},
		accessibleZonesForModal(accessibleZones) {
			let zones = [];
			for(let zoneId in accessibleZones) {
				zones.push({
					id: zoneId,
					name: accessibleZones[zoneId],
					actionDelete: $gettext('Delete')
				});
			}
			return zones;
		},
		async createApiKey() {
			ApiKeyRessource.create(this.modalTempData.newApiKey).then(() => {
				this.loadKeyList();
			});
		},
		async editApiKey() {
			ApiKeyRessource.update(this.modalTempData.editApiKey).then(() => {
				this.loadKeyList();
			});
		},
		async deleteApiKey() {
			ApiKeyRessource.delete(this.modalTempData.deleteApiKey.id).then(() => {
				this.loadKeyList();
			});
		},
		async editAccessibleZones() {
			let apiKey = this.modalTempData.apiKeyToManageAccessibleZones;
			let accessibleZones = this.modalTempData.accessibleZones;

			let addedZoneIds = accessibleZones.filter((zone) => {
				return !apiKey.accessibleZones[zone.id];
			}).map((zone) => zone.id);

			let removedZoneIds = Object.keys(apiKey.accessibleZones).filter((zoneId) => {
				return !accessibleZones.find((zone) => zone.id == zoneId);
			});

			let promises = [];

			if(addedZoneIds.length) promises.push(ApiKeyRessource.connect(apiKey, addedZoneIds));
			if(removedZoneIds.length) promises.push(ApiKeyRessource.disconnect(apiKey, removedZoneIds));

			Promise.all(promises).then(() => {
				this.loadKeyList();
			});
		},
		openModal(id) {
			let element = document.getElementById(id);
			if(!element) return;

			if(!this.modalCache[id])
				this.modalCache[id] = new bootstrap.Modal(element);
			
			this.modalCache[id].show();
			setTimeout(function(){
				jQuery(element).find('input[data-focus-on-modal-open]').focus();
			},500);
		},
		closeModal(id){
			this.modalCache[id].hide();
		},
		async loadAddableZones() {
			if(!this.modalTempData || !this.modalTempData.accessibleZones) {
				this.addableZoneList = [];
				return null;
			}

			let zoneList = await DnsZoneRessource.list();
			let accessibleZones = this.modalTempData.accessibleZones;
			
			let result = zoneList.filter((zone) => {
				return !accessibleZones.find((accessibleZone) => accessibleZone.id == zone.id);
			}).sort((a, b) => a.name.localeCompare(b.name));
			this.addableZoneList = result;
		}
	},
	computed: {
	},
	watch: {
		zoneToAdd() {
			if(!this.zoneToAdd) return;
			let zone = this.addableZoneList.find((zone) => zone.id == this.zoneToAdd);
			if(!zone) return;
			this.modalTempData.accessibleZones.push({
				id: zone.id,
				name: zone.name,
				actionDelete: $gettext('Delete')
			});
			this.zoneToAdd = null;
			this.loadAddableZones();
		}
	},
	components: {
		RessourceTable,
		BootstrapModal
	},
};

</script>
<style lang="less">

.ressourceTableWrapper {

	&.dnsZoneTable {
		td[data-prop="name"] {
			cursor: pointer;
			color: #c11818;
		}
	}

	td[data-prop="actionEdit"],td[data-prop="actionDelete"],td[data-prop="accessibleZonesCount"] {
		cursor: pointer;
		color: #c11818;
	}

}


</style>