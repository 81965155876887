<template>
	<div class="ressourceTableWrapper">
		<div class="filter">
			<input type="text" v-model="filter" placeholder="Filter" />
		</div>
		<table class="ressourceTable">
			<thead>
				<tr>
					<th v-for="(name, prop) in columnLabels" :data-prop="prop" @click="this.sortByProp = prop; this.sortAsc = !this.sortAsc">{{ name }}</th>
				</tr>
			</thead>
			<tbody :class="{ sortedAsc: this.sortAsc, sortedDesc: !this.sortAsc}">
				<tr v-for="dataRow in visibleSortedRows">
					<template v-for="prop in selectedProps">
						<td v-if="prop.toLowerCase().endsWith('html')" @click="clicked(dataRow, prop, $event)" :data-prop="prop" v-html="dataRow[prop]"></td>
						<td v-else-if="Array.isArray(dataRow[prop])" @click="clicked(dataRow, prop, $event)" :data-prop="prop">
							<ul>
								<li v-for="item in dataRow[prop]">{{ item }}</li>
							</ul>
						</td>
						<td v-else @click="clicked(dataRow, prop, $event)" :data-prop="prop">{{ dataRow[prop] }}</td>
					</template>
				</tr>
				<tr v-if="visibleSortedRows.length == 0" class="emptyRow">
					<td :colspan="selectedProps.length" class="emptyCol">{{ $gettext('No items exist.') }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

import _ from 'underscore';

export default {
	name: 'ResourceTable',
	props: {
		dataRows: {
			type: Array,
			default: () => [],
		},
		allProps: {
			type: Object,
			default: () => {},
		},
		onlyFilterBy: {
			default: null,
		},
	},
	data() {
		return {
			selectedProps: [],
			sortByProp: '',
			sortAsc: true,
			filter: '',
		};
	},
	created() {
		this.selectedProps = _.keys(this.allProps);
		this.sortByProp = this.selectedProps[0];
	},
	beforeUnmount() {
	},
	methods: {
		clicked(dataRow, prop, event) {
			this.$emit('click', dataRow, prop, event);
		},
	},
	computed: {
		visibleProps() {
			return _.pick(this.allProps, this.selectedProps);
		},
		visibleSortedRows() {
			let that = this;
			let filtered = this.dataRows.filter((dataRow) => {
				if(that.onlyFilterBy) {
					if(dataRow[that.onlyFilterBy] === null || dataRow[that.onlyFilterBy] === undefined)
						return false;
					return dataRow[that.onlyFilterBy].toString().toLowerCase().includes(this.filter.toLowerCase());
				}
				else {
					return _.values(dataRow).some((value) => {
						if(value === null || value === undefined)
							return false;
						return value.toString().toLowerCase().includes(this.filter.toLowerCase());
					});
				}
			});

			let sorted;
			let sortField = this.sortByProp;

			if(this.allProps[sortField] && this.allProps[sortField].sortField)
				sortField = this.allProps[sortField].sortField;
			
			if(this.sortAsc)
				sorted = _.sortBy(filtered, sortField);
			else
				sorted = _.sortBy(filtered, sortField).reverse();
			
			return sorted;
		},		
		columnLabels() {
			let labels = {};
			// Iterate over visible props with key and value
			_.each(this.visibleProps, (value, key) => {
				// If the value is a string, use it as label
				if(typeof value == 'string')
					labels[key] = value;
				// If the value is an object, use the label property
				else if(typeof value == 'object' && value.label)
					labels[key] = value.label;
				// else use the key
				else
					labels[key] = key;
			});
			return labels;
		},
	},
	watch: {
		allProps: {
			handler() {
				this.selectedProps = _.keys(this.allProps);
				this.sortByProp = this.selectedProps[0];
			}
		},
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

	.ressourceTableWrapper {
		width: 100%;

		.filter {
			margin-bottom: 10px;
			text-align: right;

			input {
				width: 250px;
				padding: 12px 20px;
				margin: 8px 0;
				box-sizing: border-box;
			}
		}

		table.ressourceTable {
			width: 100%;

			th, td {
				text-align: left;
				padding: 5px;
			}

			tr:nth-child(even) {
				background-color: #f2f2f2;
			}

			tr:hover {
				background-color: #ddd;
			}

			th {
				background-color: #4CAF50;
				color: white;
			}

			td ul {
				margin-left: 0;
    			padding-left: 17px;

				li {
					list-style-type: square;
				}
			}
			
		}

	}

	

</style>