<template>
	<div :class="['dns-zone-view']">
		<h1 v-if="currentDnsZone == null">{{ $gettext('DNS Zones') }}</h1>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showCreateZoneModal">{{ $gettext('Create new DNS Zone') }}</a>
		<a class="btn btn-primary" href="javascript:void(0)" @click="showImportZonefileModal">{{ $gettext('Import zonefile') }}</a>
		<RessourceTable :class="{'dnsZoneTable': true}" v-if="currentDnsZone == null" :dataRows="zoneList" onlyFilterBy="nameWithMainZone" :allProps="dnsZoneTableAllProps" @click="dnsZoneTableClickHandler" />
	</div>
	<BootstrapModal id="createDnsZone" modalSize="large" :title=" $gettext('Create a new DNS Zone') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="createDnsZone()">
		<!-- Bootstrap form -->
		<form v-if="modalTempData && modalTempData.newZone">
			<div class="mb-3">
				<label for="create-name" class="form-label">{{ $gettext('Name') }}</label>
				<input type="text" class="form-control" id="create-name" v-model="modalTempData.newZone.name">
			</div>
			<div class="mb-3">
				<label for="create-isAlias" class="form-label">
					<input type="checkbox" class="form-control" style="display: inline-block;" id="create-isAlias" value="1" v-model="modalTempData.newZone.isAlias">
					{{ $gettext('Zone should be an alias of another zone.') }}
				</label>
			</div>
			<div v-if="modalTempData.newZone.isAlias" class="mb-3">
				<label for="create-isAliasOf" class="form-label">{{ $gettext('Main zone') }}:</label>
				<select class="form-control" id="create-isAliasOf" v-model="modalTempData.newZone.isAliasOf">
					<option v-for="zone in zoneListWithoutAliases" :value="zone.id">{{ zone.name }}</option>
				</select>
			</div>
			<div class="mb-3">
				<label for="create-email" class="form-label">{{ $gettext('Email') }}</label>
				<input type="text" class="form-control" id="create-email" v-model="modalTempData.newZone.email">
			</div>
			<div class="mb-3">
				<label for="create-ttl" class="form-label">{{ $gettext('TTL') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="create-ttl" step="1" v-model="modalTempData.newZone.ttl">
			</div>
			<div class="mb-3">
				<label for="create-expire" class="form-label">{{ $gettext('Expire') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="create-expire" step="1" v-model="modalTempData.newZone.expire">
			</div>
			<div class="mb-3">
				<label for="create-refresh" class="form-label">{{ $gettext('Refresh') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="create-refresh" step="1" v-model="modalTempData.newZone.refresh">
			</div>
			<div class="mb-3">
				<label for="create-retry" class="form-label">{{ $gettext('Retry') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="create-retry" step="1" v-model="modalTempData.newZone.retry">
			</div>
			<div class="mb-3">
				<label for="create-minimum" class="form-label">{{ $gettext('Minimum') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="create-minimum" step="1" v-model="modalTempData.newZone.minimum">
			</div>	
			<div class="mb-3">
				<label for="create-externalReference" class="form-label">{{ $gettext('External Reference') }}</label>
				<textarea rows="5" class="form-control" id="create-externalReference" v-model="modalTempData.newZone.externalReference" />
			</div>			
		</form>
	</BootstrapModal>
	<BootstrapModal id="editDnsZone" modalSize="large" :title=" $gettext('Edit this DNS Zone') " :uiCloseVisible="true" :submitTitle="$gettext('Save')" @submit="editDnsZone()">
		<!-- Bootstrap form -->
		<form v-if="modalTempData && modalTempData.editZone">
			<div class="mb-3">
				<label for="edit-email" class="form-label">{{ $gettext('Email') }}</label>
				<input type="text" class="form-control" id="edit-email" v-model="modalTempData.editZone.email">
			</div>
			<div class="mb-3">
				<label for="edit-ttl" class="form-label">{{ $gettext('TTL') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="edit-ttl" step="1" v-model="modalTempData.editZone.ttl">
			</div>
			<div class="mb-3">
				<label for="edit-expire" class="form-label">{{ $gettext('Expire') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="edit-expire" step="1" v-model="modalTempData.editZone.expire">
			</div>
			<div class="mb-3">
				<label for="edit-refresh" class="form-label">{{ $gettext('Refresh') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="edit-refresh" step="1" v-model="modalTempData.editZone.refresh">
			</div>
			<div class="mb-3">
				<label for="edit-retry" class="form-label">{{ $gettext('Retry') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="edit-retry" step="1" v-model="modalTempData.editZone.retry">
			</div>
			<div class="mb-3">
				<label for="edit-minimum" class="form-label">{{ $gettext('Minimum') }}</label>
				<input type="number" min="30" max="86400" class="form-control" id="edit-minimum" step="1" v-model="modalTempData.editZone.minimum">
			</div>	
			<div class="mb-3">
				<label for="edit-externalReference" class="form-label">{{ $gettext('External Reference') }}</label>
				<textarea rows="5" class="form-control" id="edit-externalReference" v-model="modalTempData.editZone.externalReference" />
			</div>			
		</form>
	</BootstrapModal>
	<BootstrapModal id="importZoneFile" modalSize="large" :title=" $gettext('Import a DNS zonefile') " :uiCloseVisible="true" :submitTitle="$gettext('Import')" @submit="importZoneFile()">
		<!-- Bootstrap form -->
		<form v-if="modalTempData && Object.keys(modalTempData).includes('importZoneName')">
			<p>{{ $gettext('Use this feature to import a whole DNS zone and its DNS records. The DNS zone must not exist in the system yet. If you want to use this method to update an existing zone, you will have to delete the zone at first.') }}</p>
			<div class="mb-3">
				<label for="import-name" class="form-label">{{ $gettext('Zone name') }}</label>
				<input type="text" class="form-control" id="import-name" v-model="modalTempData.importZoneName">
			</div>
			<div class="mb-3">
				<label for="import-zonefile" class="form-label">{{ $gettext('Upload zonefile') }}</label>
				<input type="file" class="form-control" id="import-zonefile">
			</div>	
			<div class="mb-3">
				<label for="import-overrideSoaSettings" class="form-label">
					<input type="checkbox" class="form-control" style="display: inline-block;" id="import-overrideSoaSettings" value="1" v-model="modalTempData.importZoneOverrideSoaSettings">
					{{ $gettext('Override the SOA-settings (ttl, refresh, expire, retry and minimum) with default values.') }}
				</label>
			</div>
			<div class="mb-3">
				<label for="import-importZoneOverrideWrongOriginNameservers" class="form-label">
					<input type="checkbox" class="form-control" style="display: inline-block;" id="import-importZoneOverrideWrongOriginNameservers" value="1" v-model="modalTempData.importZoneOverrideWrongOriginNameservers">
					{{ $gettext('Override wrong nameservers for the zone origin.') }}
				</label>
			</div>	
		</form>
	</BootstrapModal>
	<BootstrapModal id="deletionConfirmDnsZone" :title="$gettext('Delete?')" :uiCloseVisible="true" :submitTitle="$gettext('Delete')" @submit="deleteDnsZone()">
		{{ $gettext('Are you sure you want to delete this DNS Zone?') }}
	</BootstrapModal>
	<BootstrapModal id="zoneFile" modalSize="large" :title="$gettext('View zonefile')" :uiCloseVisible="true" :uiSubmitVisible="false">
		<pre v-if="this.modalTempData && this.modalTempData.zoneFile">{{ this.modalTempData.zoneFile }}</pre>
	</BootstrapModal>
	<BootstrapModal id="healthStatusDnsZone" modalSize="large" :title="$gettext('View zone health status')" :uiCloseVisible="true" :uiSubmitVisible="true" @submit="healthCheckZone" :submitTitle="'Re-execute helathcheck'" :closeOnSubmit="false">
		<div class="healthCheckResult" v-if="this.modalTempData && this.modalTempData.zoneForHealth && this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo">
			<p>{{ $gettext('These are the results of our verification test for this zone:') }}</p>
			<ul>
				<li v-if="this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.zoneFileSyntax.success">
					{{ $gettext('The zone file syntax looks OK.') }}
				</li>
				<li v-else>
					{{ $gettext('The zone file syntax is NOT OK. Output:') }}
					<pre>{{ this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.zoneFileSyntax.info.output }}</pre>
				</li>
				<li>
					<span v-if="this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.nsDelegation.success">{{ $gettext('The NS delegation looks OK.') }}</span>
					<span v-else>{{ $gettext('The NS delegation is NOT OK.') }}</span>
					<br/>
					<span>{{ $gettext('These nameservers are responsible for this zone, according to the public domain name system:') }}</span>
					<ul>
						<li v-for="ns in this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.nsDelegation.info.propagatedNameServers">{{ ns }}</li>
					</ul>
				</li>
				<template v-if="this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.nsDelegation.success">
					<li v-if="this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.directResolve.success">
						{{ $gettext('Resolving a dns record for this zone seems to work.') }}
					</li>
					<li v-else>
						{{ $gettext('Resolving a dns record for this zone DOES NOT seem to work.') }}
					</li>
				</template>
				<li v-else>
					{{ $gettext('Resolving a dns record does not work, because NS delegation has to be fixed at first.') }}
				</li>
				<li>
					{{ $gettext('We checked the public dns system using the following public DNS resolver:') }} {{ this.modalTempData.zoneForHealth.lastZoneHealthCheckInfo.usedPublicResolver }}
				</li>
				<li v-if="this.modalTempData.zoneForHealth.lastZoneHealthCheckAt">
					{{ $gettext('The test has been executed at ') }} {{ this.modalTempData.zoneForHealth.lastZoneHealthCheckAt }}
				</li>
			</ul>
		</div>
	</BootstrapModal>
</template>

<script>

import _ from 'underscore';
import DnsZoneRessource from '../../ApiRessource/DnsZoneRessource';
import RessourceTable from '../Tables/RessourceTable.vue';
import BootstrapModal from '../Modals/Bootstrap.vue';

export default {
	name: 'DnsZoneView',
	emits: ['switchView'],
	data() {
		return {
			currentDnsZone: null,
            modalCache: {},
			modalTempData: null,
			zoneList: [],
			dnsZoneTableAllProps: {
				'displayNameHtml': {
					'label': 'Name',
					'sortField': 'nameWithMainZone',
					'filterField': 'nameWithMainZone',
				},
				'healthyHtml': 'Healthy?',
				'externallyManagedByHtml': 'Ext. Managed?',
				'ttl': 'TTL',
				'expire': 'Expire',
				'refresh': 'Refresh',
				'retry': 'Retry',
				'minimum': 'Minimum',
				'dyndnsAccountCount': 'Dyndns Acc.',
				'certificateCount': 'Certificates',
				'actionZoneFile': 'Zonefile',
				'actionEdit': 'Edit',
				'actionDelete': 'Delete'
			},
		};
	},
	async created() {
		this.loadZoneList();
	},
	beforeUnmount() {
	},
	methods: {
		getZoneById(id, zoneList = null) {
			if(!zoneList) zoneList = this.zoneList;
			return zoneList.find((zone) => zone.id == id);
		},
		importZoneFile() {			
			var file = document.getElementById('import-zonefile').files[0];
			var reader = new FileReader();
			reader.readAsText(file,'UTF-8');
			reader.onload = readerEvent => {
				var content = readerEvent.target.result;
				DnsZoneRessource.importZoneFile(this.modalTempData.importZoneName, content, this.modalTempData.importZoneOverrideSoaSettings, this.modalTempData.importZoneOverrideWrongOriginNameservers).then(() => {
					this.loadZoneList();
				});
			}
		},
		showCreateZoneModal() {
			this.modalTempData = {
				newZone: {
					name: '',
					email: '',
					ttl: 60,
					expire: 86400,
					refresh: 300,
					retry: 60,
					minimum: 60,
					externalReference: '',
					isAlias: 0,
					isAliasOf: null
				}
			};
			this.openModal('createDnsZone');
		},
		healthCheckZone() {
			DnsZoneRessource.healthcheck(this.modalTempData.zoneForHealth.id).then(async () => {
				await this.loadZoneList();
				this.modalTempData.zoneForHealth = this.getZoneById(this.modalTempData.zoneForHealth.id);
			});
		},
		showImportZonefileModal() {
			this.modalTempData = {
				importZoneName: '',
				importZoneFile: null,
				importZoneOverrideSoaSettings: false,
				importZoneOverrideWrongOriginNameservers: false
			};
			this.openModal('importZoneFile');
		},
		async dnsZoneTableClickHandler(row, prop, event) {
			if(prop == 'displayNameHtml') {
				if(row.isAliasOf) {
					let mainZone = this.getZoneById(row.isAliasOf);
					if(mainZone)
						alert($gettext('This zone is an alias. No modification allowed.\nMain zone: ') + mainZone.name);
					else
						alert($gettext('This zone is an alias. No modification allowed. Your API key does not have access to the main zone.'));
				}
				else {
					this.$emit('switchView', {
						dnsZone: row,
						view: 'dns-records'
					});
				}
			}

			if(prop == 'healthyHtml') {
				this.modalTempData = {zoneForHealth: row};
				this.openModal('healthStatusDnsZone');
			}

			if(prop == 'certificateCount') {
				this.$emit('switchView', {
					dnsZone: row,
					view: 'certificates'
				});
			}

			if(prop == 'dyndnsAccountCount') {
				this.$emit('switchView', {
					dnsZone: row,
					view: 'dyndns-accounts'
				});
			}

			if(prop == 'actionEdit') {
				this.modalTempData = {editZone: row};
				this.openModal('editDnsZone');
			}

			if(prop == 'actionDelete') {
				this.modalTempData = {deleteZone: row};
				if(event.shiftKey)
					this.deleteDnsZone();
				else
					this.openModal('deletionConfirmDnsZone');
			}

			if(prop == 'actionZoneFile') {
				this.modalTempData = {zoneFile: await DnsZoneRessource.getZoneFile(row.id)};
				this.openModal('zoneFile');
			}
		},
		async loadZoneList() {
			let zoneList = await DnsZoneRessource.list();

			this.zoneList = zoneList.map((zone) => {
				zone.displayNameHtml = zone.name;
				zone.healthyHtml = zone.lastZoneHealthCheckOk ? '<i class="fas fa-circle health-ok"></i>' : '<i class="fas fa-circle health-error"></i>';
				zone.nameWithMainZone = zone.name;
				if(zone.isAliasOf) {
					let mainZone = this.getZoneById(zone.isAliasOf, zoneList);
					if(mainZone) {
						zone.nameWithMainZone = mainZone.name + '|' + zone.nameWithMainZone;
						zone.displayNameHtml = '<span title="'+$gettext('Alias of')+' \''+mainZone.name +'\'"><i class="fas fa-turn-up"></i> ' + zone.name + '</span>';
					}
					else
						zone.displayNameHtml = '<i class="fas fa-turn-up"></i> ' + zone.name;
				}
				zone.externallyManagedByHtml = zone.externallyManagedBy ? zone.externallyManagedBy
																				.replace('PLESK|','<img src="/assets/dns-manager-frontend/img/plesk.jpg" class="plesk_icon">')
																		: '';
				zone.actionZoneFile = $gettext("Zonefile");
				zone.actionEdit = $gettext("Edit");
				zone.actionDelete = $gettext("Delete");
				return zone;
			});
		},
		async createDnsZone() {
			
            if(!this.modalTempData.isAlias) this.modalTempData.isAliasOf = null;
			DnsZoneRessource.create(this.modalTempData.newZone).then(() => {
				this.loadZoneList();
			});
		},
		async editDnsZone() {
			DnsZoneRessource.update(this.modalTempData.editZone).then(() => {
				this.loadZoneList();
			});
		},
		async deleteDnsZone() {
			DnsZoneRessource.delete(this.modalTempData.deleteZone.id).then(() => {
				this.loadZoneList();
			});
		},
		openModal(id) {
			let element = document.getElementById(id);
			if(!element) return;

			if(!this.modalCache[id])
				this.modalCache[id] = new bootstrap.Modal(element);
			
			this.modalCache[id].show();
			setTimeout(function(){
				jQuery(element).find('input[data-focus-on-modal-open]').focus();
			},500);
		},
		closeModal(id){
			this.modalCache[id].hide();
		},
	},
	computed: {
		zoneListWithoutAliases() {
			return this.zoneList.filter((zone) => !zone.isAliasOf).sort((a, b) => a.name.localeCompare(b.name));
		}
	},
	watch: {
	},
	components: {
		RessourceTable,
		BootstrapModal
	},
};

</script>
<style lang="less">


.ressourceTableWrapper {
	&.dnsZoneTable {
		td[data-prop="displayNameHtml"] {
			cursor: pointer;
			color: #c11818;
		}
	}


	td[data-prop="value"] {
		word-break: break-word;
		max-width: 20vw;
	}

	td[data-prop="actionEdit"],td[data-prop="actionDelete"],td[data-prop="actionZoneFile"],td[data-prop="certificateCount"],td[data-prop="dyndnsAccountCount"] {
		cursor: pointer;
		color: #c11818;
	}


	th[data-prop="healthyHtml"],
	td[data-prop="healthyHtml"] {
		text-align: center!important;

		i {
			cursor: pointer;
		}

		i.health-ok {
			color: #4caf50;
		}
		
		@keyframes pulse {
			0% {
				opacity: 1;
			}
			50% {
				opacity: 0.3;
			}
			100% {
				opacity: 1;
			}
		}
		// With pulsing animation
		i.health-error {
			color: #c11818;
			animation: pulse 2s infinite;
		}
	}

	td[data-prop="displayNameHtml"] {
		i {
			color: #666;
			transform: scale(-1, 1);
			margin-right: 10px;
		}
	}

	td[data-prop="externallyManagedByHtml"] {
		img {
			max-height: 20px;
			max-width: 30px;
			float: right;
		}
	}

	.sortedDesc {
		td[data-prop="displayNameHtml"] {
			i {
				transform: scale(-1, -1);
			}
		}
	}
}

.healthCheckResult {
	ul {
		margin: 0;
		padding-left: 17px;

		li {
			list-style: square;
		}
	}
}


</style>