<template>
	<div :class="['settings-view']">
		<h2>{{ $gettext('Settings') }}</h2>
		<!-- Bootstrap form with two text inputs: apiUrl and apiKey -->
		<form>
			<div class="form-group">
				<label for="apiUrl">{{ $gettext('API: URL') }}</label>
				<input type="text" class="form-control" id="apiUrl" v-model="settings.apiUrl"  @change="changed" />
				<small>{{ $gettext('Should be of form https://some-domain.com/api/v1') }}</small>
			</div>
			<div class="form-group">
				<label for="apiKey">{{ $gettext('API: Key') }}</label>
				<input type="text" class="form-control" id="apiKey" v-model="settings.apiKey" @change="changed" />
			</div>
		</form>
	</div>
</template>

<script>

import _ from 'underscore';
import GlobalStateManager from '../StateManagers/GlobalStateManager';

export default {
	name: 'SettingsView',
	data() {
		return {
			settings: {
				type: Object,
				default: () => {
					return {
						apiUrl: '',
						apiKey: ''
					};
				}
			}
		};
	},
	created() {
		this.settings = GlobalStateManager.settings;
	},
	beforeUnmount() {
	},
	methods: {
		changed() {
			GlobalStateManager.settings = this.settings;
			GlobalStateManager.saveSettings();
		}
	},
	computed: {
	},
	components: {
	},
};

</script>
<style lang="less" scoped>

</style>