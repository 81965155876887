
window.$gettext = (key) => {

    if (!window.j77SystemTranslationVueLanguage)
      window.j77SystemTranslationVueLanguage = {};
  
    if ('singular' in window.j77SystemTranslationVueLanguage) {
      if (Object.keys(window.j77SystemTranslationVueLanguage['singular']).includes(key))
        return window.j77SystemTranslationVueLanguage['singular'][key];
    }
    return key;
  }
  
  
  export default {  
  
    install(app, options) {
      
      app.config.globalProperties.$gettext = window.$gettext;
      
      app.provide('j77SystemTranslationVue', options);
    }
  }
  
  